exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-book-the-choir-js": () => import("./../../../src/pages/book-the-choir.js" /* webpackChunkName: "component---src-pages-book-the-choir-js" */),
  "component---src-pages-corporate-workshops-js": () => import("./../../../src/pages/corporate-workshops.js" /* webpackChunkName: "component---src-pages-corporate-workshops-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-donation-success-js": () => import("./../../../src/pages/donation-success.js" /* webpackChunkName: "component---src-pages-donation-success-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-choir-js": () => import("./../../../src/pages/join-the-choir.js" /* webpackChunkName: "component---src-pages-join-the-choir-js" */),
  "component---src-pages-meet-the-choir-js": () => import("./../../../src/pages/meet-the-choir.js" /* webpackChunkName: "component---src-pages-meet-the-choir-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-school-program-js": () => import("./../../../src/pages/school-program.js" /* webpackChunkName: "component---src-pages-school-program-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */)
}

